<template>
  <component :is="as" :class="cssClasses.contentHolderClasses">
    <div :class="cssClasses.contentBodyClasses">
      <slot />
    </div>
    <div v-if="hasFooter" :class="cssClasses.contentFooterClasses">
      <slot name="footer"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'VueContentHolder',
  props: {
    as: {
      type: String,
      default: 'div',
    },
    padding: {
      type: Array,
    },
    footerPadding: {
      type: Array,
    },
  },
  computed: {
    cssClasses() {
      const contentHolderClasses = [this.$style['content-holder']];

      if (this.hasFooter) {
        contentHolderClasses.push(this.$style['content-holder-with-footer']);
      }

      const contentBodyClasses = [this.$style['content-body']];
      const contentFooterClasses = [this.$style['content-footer']];
      if (this.padding) {
        switch (this.padding.length) {
          case 1:
            contentBodyClasses.push(this.$style[`content-body-top-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-right-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-bottom-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-left-space-${this.padding[0]}`]);
            break;
          case 2:
            contentBodyClasses.push(this.$style[`content-body-top-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-right-space-${this.padding[1]}`]);
            contentBodyClasses.push(this.$style[`content-body-bottom-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-left-space-${this.padding[1]}`]);
            break;
          case 3:
            contentBodyClasses.push(this.$style[`content-body-top-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-right-space-${this.padding[1]}`]);
            contentBodyClasses.push(this.$style[`content-body-bottom-space-${this.padding[2]}`]);
            contentBodyClasses.push(this.$style[`content-body-left-space-${this.padding[1]}`]);
            break;
          case 4:
            contentBodyClasses.push(this.$style[`content-body-top-space-${this.padding[0]}`]);
            contentBodyClasses.push(this.$style[`content-body-right-space-${this.padding[1]}`]);
            contentBodyClasses.push(this.$style[`content-body-bottom-space-${this.padding[2]}`]);
            contentBodyClasses.push(this.$style[`content-body-left-space-${this.padding[3]}`]);
            break;
        }
      }

      if (this.footerPadding) {
        switch (this.footerPadding.length) {
          case 1:
            contentFooterClasses.push(
              this.$style[`content-footer-top-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-right-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-bottom-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-left-space-${this.footerPadding[0]}`],
            );
            break;
          case 2:
            contentFooterClasses.push(
              this.$style[`content-footer-top-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-right-space-${this.footerPadding[1]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-bottom-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-left-space-${this.footerPadding[1]}`],
            );
            break;
          case 3:
            contentFooterClasses.push(
              this.$style[`content-footer-top-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-right-space-${this.footerPadding[1]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-bottom-space-${this.footerPadding[2]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-left-space-${this.footerPadding[1]}`],
            );
            break;
          case 4:
            contentFooterClasses.push(
              this.$style[`content-footer-top-space-${this.footerPadding[0]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-right-space-${this.footerPadding[1]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-bottom-space-${this.footerPadding[2]}`],
            );
            contentFooterClasses.push(
              this.$style[`content-footer-left-space-${this.footerPadding[3]}`],
            );
            break;
        }
      }

      return { contentHolderClasses, contentBodyClasses, contentFooterClasses };
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.content-holder {
  width: 100%;
  height: 100%;
}

.content-holder-with-footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}

.content-body,
.content-footer {
  padding: 0;
}

.content-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
}

@each $variation, $space in $palette-spaces {
  .content-body-top-#{$variation} {
    padding-top: $space;
  }
  .content-body-bottom-#{$variation} {
    padding-bottom: $space;
  }
  .content-body-left-#{$variation} {
    padding-left: $space;
  }
  .content-body-right-#{$variation} {
    padding-right: $space;
  }

  .content-footer-top-#{$variation} {
    padding-top: $space;
  }
  .content-footer-bottom-#{$variation} {
    padding-bottom: $space;
  }
  .content-footer-left-#{$variation} {
    padding-left: $space;
  }
  .content-footer-right-#{$variation} {
    padding-right: $space;
  }
}
</style>
